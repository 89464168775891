import axiosInstance from "./Api";

export default {
  async getHomePageData() {
    let response = await axiosInstance().get(`/student/home-page-data`);

    return response;
  },

  async getRequiredDocs(payload) {
    let response = await axiosInstance().get(
      `/student/profile/required-docs/${payload.course_level_id}`
    );

    return response;
  },

  async getStudentProfile() {
    let response = await axiosInstance().get(`/student/profile`);

    return response;
  },

  async getCoursePreferences() {
    let response = await axiosInstance().get(`/student/course-preferences`);

    return response;
  },

  async saveStudentProfile(payload) {
    let response = await axiosInstance().post(`/student/profile`, payload);

    return response;
  },

  async getStudentStatus(payload) {
    let response = await axiosInstance().get(`/student/profile/status`);

    return response;
  },

  async uploadStudentProfilePicture(payload) {
    const {} = payload;

    let response = await axiosInstance().post(`/student/profile-image`);

    return response;
  },

  async getAssignedCounselors() {
    let response = await axiosInstance().get(
      `/student/profile/assigned-counselors`
    );

    return response;
  },

  async getCourses(payload) {
    let response = await axiosInstance().get(`/student/course/all`, {
      params: payload,
    });

    return response;
  },

  async getUniversityDetailsWithCourses(payload) {
    let response = await axiosInstance().get(
      `/student/course/university-details-with-courses/${payload.university_id}`
    );

    return response;
  },

  async ApplyCourse(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(
      `/student/course/apply/${course_id}`,
      payload
    );

    return response;
  },

  async cancelApplication(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(
      `/student/course/cancel/${course_id}`
    );

    return response;
  },

  async getAppliedCourses() {
    let response = await axiosInstance().get(`/student/course/all-applied`);

    return response;
  },

  async setApplicationsPriority(payload) {
    let response = await axiosInstance().post(
      `/student/course/application-priority`,
      payload
    );

    return response;
  },

  async getApplicationChat(payload) {
    const { sa_mapper_id } = payload;

    return axiosInstance().get(
      `/student/course/application/chat/${sa_mapper_id}`
    );
  },

  async sendApplicationChat(payload) {
    const { sa_mapper_id } = payload;

    return axiosInstance().post(
      `/student/course/application/chat/${sa_mapper_id}`,
      payload
    );
  },

  async getFavouriteCourses() {
    let response = await axiosInstance().get(`/student/course/favourite/all`);

    return response;
  },

  async addCourseToFavourite(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(
      `/student/course/favourite/add/${course_id}`
    );

    return response;
  },

  async removeCourseFromFavourite(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(
      `/student/course/favourite/remove/${course_id}`
    );

    return response;
  },

  async getSuggestedCourses() {
    let response = await axiosInstance().get(`/student/course/suggestions/all`);

    return response;
  },

  async acceptCourseSuggestion(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(
      `/student/course/suggestions/accept/${course_id}`
    );

    return response;
  },

  async rejectCourseSuggestion(payload) {
    const { course_id } = payload;

    let response = await axiosInstance().post(
      `/student/course/suggestions/reject/${course_id}`
    );

    return response;
  },

  async getAllDocuments(payload) {
    let response = await axiosInstance().get(`/student/document/all`);

    return response;
  },

  async getStaffDocuments() {
    let response = await axiosInstance().get(`/student/document/staff/all`);

    return response;
  },

  async RemoveDocument(payload) {
    const { sd_mapper_id } = payload;

    let response = await axiosInstance().delete(
      `/student/document/remove/${sd_mapper_id}`
    );

    return response;
  },

  async getAllVisaDocuments(payload) {
    let response = await axiosInstance().get(`/student/visa-document/all`);

    return response;
  },

  async getStaffVisaDocuments() {
    let response = await axiosInstance().get(
      `/student/visa-document/staff/all`
    );

    return response;
  },

  async RemoveVisaDocument(payload) {
    const { svd_id } = payload;

    let response = await axiosInstance().delete(
      `/student/visa-document/remove/${svd_id}`
    );

    return response;
  },

  async applicationChecklist() {
    return await axiosInstance().get(`/student/application-checklist`);
  },

  async visaChecklist() {
    return await axiosInstance().get(`/student/visa-checklist`);
  },

  async getServicesAvailed() {
    let response = await axiosInstance().get(`/student/services/availed`);

    return response;
  },

  async getAllFreeResources() {
    let response = await axiosInstance().get(`/student/all-resources`);

    return response;
  },

  async getAvailableCoupons(payload) {
    let response = await axiosInstance().get(`/student/available-coupons`, {
      params: payload,
    });
    return response;
  },

  async getCompletedFeatures() {
    let response = await axiosInstance().get(
      `/student/services/completed-features`
    );

    return response;
  },

  async makeStripePayment(payload) {
    const { plan_id, url, cc_id, part_payment } = payload;

    let response = await axiosInstance().get(
      `/student/stripe-payment/make/${plan_id}`,
      {
        params: { url, cc_id, part_payment },
      }
    );

    return response;
  },

  async verifyTransaction(payload) {
    const { transaction_id } = payload;

    let response = await axiosInstance().get(
      `/student/stripe-payment/verify/${transaction_id}`
    );

    return response;
  },

  async makePayUPayment(payload) {
    const { plan_id, cc_id, part_payment } = payload;

    let response = await axiosInstance().get(
      `/student/payu-payment/make/${plan_id}`,
      {
        params: {
          cc_id,
          part_payment,
        },
      }
    );

    return response;
  },

  async verifyPayUPayment(payload) {
    const { transaction_id } = payload;

    let response = await axiosInstance().get(
      `/student/payu-payment/verify/${transaction_id}`
    );

    return response;
  },

  async saveReferenceLead(payload) {
    return await axiosInstance().post(`/student/save-reference-lead`, payload);
  },

  async getAllUniversityPayments(payload) {
    return await axiosInstance().get(`/student/university-payment`, payload);
  },

  async makeUniversityPayment(up_id) {
    return await axiosInstance().get(
      `/student/make-university-payment/${up_id}`
    );
  },

  async verifyUniversityPayment(up_id) {
    return await axiosInstance().get(
      `/student/verify-university-payment/${up_id}`
    );
  },

  async saveAssessProfile(payload) {
    return await axiosInstance().post(`/student/save-assess-user`, payload);
  },

  async getAllLoanBanks(payload) {
    return await axiosInstance().get(`/student/loan`, { params: payload });
  },
  async studentInterestedForLoan(payload) {
    return await axiosInstance().post(
      `/student/student-interested-loan`,
      payload
    );
  },
  async saveStudentAcceptance(payload) {
    return await axiosInstance().post(`/student/save-user-acceptance`, payload);
  },
  async checkStudentAcceptance() {
    return await axiosInstance().get(`/student/check-user-acceptance`);
  },

  async getSOPQuestions(payload) {
    return axiosInstance().get(`/student/sop-questions`, {
      params: payload,
    });
  },
  async saveSOPAnswers(payload) {
    return axiosInstance().post(`/student/sop-answers`, payload);
  },

  async getSOPAnswers(payload) {
    return axiosInstance().get(`/student/sop-answers`, {
      params: payload,
    });
  },
  async checkAISSubmission() {
    return await axiosInstance().get(`/student/check-ais`);
  },

  async saveStudentQualifications(payload) {
    return await axiosInstance().post(`/student/ais-qualifications`, payload);
  },
  async saveStudentGaps(payload) {
    return await axiosInstance().post(`/student/ais-gaps`, payload);
  },
  async saveStudentCertifications(payload) {
    return await axiosInstance().post(`/student/ais-certifications`, payload);
  },
  async saveStudentTravelHistory(payload) {
    return await axiosInstance().post(`/student/ais-travel-history`, payload);
  },
  async saveStudentWorkHistory(payload) {
    return await axiosInstance().post(`/student/ais-work-history`, payload);
  },
  async saveStudentVisaRejections(payload) {
    return await axiosInstance().post(`/student/ais-visa-rejections`, payload);
  },
  async saveStudentFamilyDetails(payload) {
    return await axiosInstance().post(`/student/ais-family-details`, payload);
  },
  async getStudentAIS() {
    return await axiosInstance().get(`/student/ais`);
  },
  async addAccommodationToFavourite(acc_id) {
    return await axiosInstance().post(
      `/student/accomodation-favourites/${acc_id}`
    );
  },
  async removeAccommodationToFavourite(acc_id) {
    return await axiosInstance().post(
      `/student/accomodation-favourites-remove/${acc_id}`
    );
  },
  async getAllAccommodations(payload) {
    return await axiosInstance().get(`/student/accommodations`, {
      params: payload,
    });
  },
  async getAccommodationFavourites() {
    return await axiosInstance().get(`/student/accommodations-favourites`);
  },
  async getAccommodationRecommendations() {
    return await axiosInstance().get(`/student/accomodation-recommendation`);
  },
  async showOnCampus() {
    return await axiosInstance().get(`/student/show-oncampus`);
  },
};
